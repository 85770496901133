@mixin phone-wide {
    @media screen and (min-width: 520px) {
        @content;
    }
}
@mixin tablet {
    @media screen and (min-width: 768px) {
        @content;
    }
}

@mixin tablet-wide {
    @media screen and (min-width: 1024px) {
        @content;
    }
}

@mixin laptop {
    @media screen and (min-width: 1199px) {
        @content;
    }
}
@mixin placeholder {
    &.placeholder { @content }
    &:-moz-placeholder { @content }
    &::-webkit-input-placeholder { @content }
}
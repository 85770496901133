@font-face {
    font-family: 'Poppins';
    src: url('../fonts/Poppins/Poppins-Regular.woff2') format('woff2'),
        url('../fonts/Poppins/Poppins-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Poppins';
    src: url('../fonts/Poppins/Poppins-Medium.woff2') format('woff2'),
        url('../fonts/Poppins/Poppins-Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Poppins';
    src: url('../fonts/Poppins/Poppins-SemiBold.woff2') format('woff2'),
        url('../fonts/Poppins/Poppins-SemiBold.woff') format('woff');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Poppins';
    src: url('../fonts/Poppins/Poppins-Bold.woff2') format('woff2'),
        url('../fonts/Poppins/Poppins-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Poppins';
    src: url('../fonts/Poppins/Poppins-ExtraBold.woff2') format('woff2'),
        url('../fonts/Poppins/Poppins-ExtraBold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}


*{
    box-sizing: border-box;
}
html{
    height: 100%;
}
body{
    min-height: 100%;
    font-size: 16px;
    font-family: $font_main;
}
img{
    max-width: 100%;
    height: auto;
}
h1, h2, h3, h4, h5, h6, ul {
    margin: 0;
    padding: 0;
}
a{
    color: $color_blue;
    text-decoration: none;
    transition: .2s;
    &.link{
        border-bottom: 1px solid currentColor;
        &:hover{
            border-color: transparent;
        }
    }
}
.relative{
    position: relative;
}
.tac{
    text-align: center;
}
.ttu{
    text-transform: uppercase;
}
.bold{
    font-weight: bold;
}
.c-blue{
    color: $color_blue;
}
.c-yellow{
    color: $color_yellow;
}
.c-black{
    color: $color_black;
}

.wrapper{
}
.wrap{
    display: flex;
    height: 100%;
    min-height: 100vh;
    width: 100%;
    max-width: 1920px;
    margin: 0 auto;
    &__content{
        flex: 1 1 100%;
        padding: 24px;
        min-width: 0;
    }
}
.next{
    display: inline-block;
    width: 34px;
    height: 34px;
    margin: -5px -10px;
    padding: 5px;
    svg{
        fill: $color_deepdark;
        width: 100%;
        height: 100%;
        transition: .2s;
    }
    &:hover{
        background-color: $color_blue;
        svg{
            fill: $color_white;
        }
    }
}
.mkc_circle { animation: mck_progress 1000ms ease-in-out; }
@keyframes mck_progress { 0% { stroke-dasharray: 0, 100; }}
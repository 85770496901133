.l-body{
    font-family: $font_main;
    // max-width: 1440px;
    margin: 0 auto;

}
.l-container{
    width: 100%;
    padding: 0 15px;
    margin: 0 auto;
    @include tablet(){
        max-width: calc(100% - 20px);
        padding: 0 30px;
    }
    @include laptop(){
        width: calc(100% - 100px);
        max-width: 1440px;
    }
}
.l-header {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 5;
    padding: 25px 0 10px;
    @include tablet(){
        padding: 35px 0 20px;
    }
    &__row {
        display: flex;
        align-items: center;
    }
    &__logo {
    }
    &__nav {
        margin-left: auto;
        display: flex;
        gap: 30px;
    }
    &__nav-item {
    }
    &__nav-link {
        text-transform: uppercase;
        font-size: 18px;
        font-weight: 600;
        color: $color_white;
        &:hover{
            color: $color_yellow;
        }
    }
}

.l-main{
    position: relative;
    &:before{
        content: '';
        width: 100%;
        height: 100%;
        background-color: #000;
        position: absolute;
        top: 0;
        left: 0;
        opacity: 0.3;
        @include tablet-wide(){
            display: none;
        }
    }
    &__bg{
        height: 100vh;
        max-height: 600px;
        @include tablet(){
            width: 100%;
            height: 100vh;
            max-height: initial;
            min-height: 600px;
        }
        img{
            width: 100%;
            height: 100%;
            object-fit: cover;
            display: block;
        }
    }
    
    &__content {
        position: absolute;
        width: 100%;
        top: 50%;
        right: 0;
        z-index: 2;
    }
    &__block{
        color: $color_white;
        width: 100%;
        max-width: 100%;
        transform: translateY(-50%);
        text-align: center;
        padding-top: 40px;
        @include tablet(){
            position: absolute;
            top: 50%;
            right: 0;
            max-width: 100%;
            right: 0px;
            padding: 0 30px;
            text-align: left;
        }
        @media screen and (min-width: 800px) {
            max-width: 640px;
        }
        @include tablet-wide(){
            right: 50px;
            max-width: 680px;

        }
    }
    &__title {
        font-weight: 800;
        font-size: 40px;
        line-height: 1;
        @include tablet(){
            font-size: 70px;
        }
        @include tablet-wide(){
            // font-size: 84px;            
        }
        @include laptop(){
            font-size: 84px;  

        }
    }
    &__text {
        margin-top: 30px;
        display: block;
        display: flex;
        flex-direction: column;
        gap: 10px;
        @include tablet(){
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            grid-template-rows: repeat(2, 1fr);
            grid-column-gap: 12px;
            grid-row-gap: 10px;
        }
    }
    &__text-col {
        font-weight: 500;
        font-size: 18px;
        @include tablet(){
            white-space: nowrap;
        }
        &:nth-child(1){
            grid-area: 1 / 1 / 2 / 2;
            @include tablet(){
                align-self: flex-end;
                text-align: right;
            }
        }
        &:nth-child(2){
            grid-area: 1 / 2 / 2 / 3;
            font-weight: 700;
            font-size: 22px;
            @include tablet(){
                width: 150px;
            }
        }
        &:nth-child(3){
            grid-area: 2 / 1 / 3 / 2;
            @include tablet(){
                align-self: flex-end;
                text-align: right;
            }
        }
        &:nth-child(4){
            grid-area: 2 / 2 / 3 / 3;
            font-weight: 700;
            font-size: 22px;
            @include tablet(){
                width: 150px;
            }
        }
        &:nth-child(5){
            grid-area: 3/1/3/3;
            align-self: center;
            font-weight: 700;
            font-size: 40px;
            @include tablet(){
                grid-area: 1 / 3 / 3 / 4;
            }
        }
    }
    &__buttons {
        display: flex;
        flex-wrap: wrap;
        gap: 10px;
        max-width: 600px;
        margin-top: 20px;
        @include tablet(){
            gap: 20px;
            margin-top: 40px;
        }
        @include tablet-wide(){
            gap: 30px;
        }
        .l-button{
            flex: 1 1 270px;
            @include tablet(){
                flex: 1;
            }
        }
    }
}

.l-button {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: 15px 18px 15px 24px;
    border: 1px solid $color_black;
    border-radius: 14px;
    gap: 16px;
    color: $color_white;
    font-size: 16px;
    font-weight: 600;
    text-transform: uppercase;
    background-color: $color_black;
    cursor: pointer;
    transition: .2s;
    @include tablet(){
        font-size: 20px;
        padding: 19px 26px 19px 32px;
    }
    &:hover{
        background: $color_yellow;
        color: $color_black;
        border-color: $color_yellow;
        svg{
            transform: translate(10px,-10px);
            fill: $color_black;
        }
    }
    &_white{
        border-color: $color_white;
        background-color: transparent;
    }
    svg{
        width: 18px;
        height: 18px;
        fill: $color_white;
        transition: .2s;
        @include tablet(){
            width: 24px;
            height: 24px;
        }
    }
}


.l-strategy {
    background: #000A1B;
    overflow: hidden;
    &__row {
        @include tablet(){
            display: flex;
            align-items: center;
        }
    }
    &__img {
        max-width: 300px;
        margin: 30px auto -10px;
        @include tablet(){
            position: relative;
            flex: 0 0 120px;
            max-width: 120px;
            z-index: 3;
            img{
                width: 216px;
                max-width: initial;
            }
        }
        @include tablet-wide(){
            flex: 0 0 160px;
            max-width: 160px;
            img{
                width: 306px;
            }
        }
        @include laptop(){
            flex: 0 0 220px;
            max-width: 220px;        
            img{
                width: 480px;
            }
        }
        @media screen and (min-width: 1400px){        
            img{
                width: 516px;
            }
        }
    }
    &__content {
        flex: 1 1 100%;
        background-color: $color_blue;
        color: $color_white;
        position: relative;
        padding: 30px;
        font-size: 18px;
        font-weight: 500;
        line-height: 1.5;
        border-radius: 10px;
        text-align: center;
        @include tablet(){
            border-radius: 10px 0px 0px 10px;
            padding: 44px 0 44px 130px;
            text-align: left;
            font-size: 18px;
            &:after{
                content: '';
                width: 10000px;
                height: 100%;
                position: absolute;
                left: 100%;
                top: 0;
                background-color: $color_blue;
            }
        }
        @include tablet-wide(){
            border-radius: 16px 0px 0px 16px;
            padding: 64px 0 64px 180px;
        }
        @include laptop(){
            border-radius: 16px 0px 0px 16px;
            padding: 80px 0 80px 280px;
            font-size: 20px;
        }
        @media screen and (min-width: 1400px){            
            padding: 104px 0 104px 330px;
        }
    }
    &__title {
        font-size: 30px;
        font-weight: 700;
        text-transform: uppercase;
        line-height: 1.2;
        @include tablet(){
            font-size: 32px;
        }
        @include tablet-wide(){
            font-size: 46px;
        }
        @include laptop(){
            font-size: 50px;
        }
        @media screen and (min-width: 1400px){
            font-size: 60px;
        }
    }
}



.l-access {
    background:  $color_black;
    color: $color_white;
    padding: 60px 0;
    @include tablet-wide(){
        padding: 160px 0 100px;
    }
    &__title {
        text-align: center;
        font-size: 40px;
        font-weight: bold;
        text-transform: uppercase;
        @include tablet-wide(){
            font-size: 50px;
        }
        @include laptop(){
            font-size: 60px;
        }
        br{
            display: none;
            @include tablet-wide(){
                display: initial;
            }
        }
    }
    &__subtitle {
        text-align: center;
        margin-top: 15px;
        font-weight: 500;
        line-height: 1.5;
        font-size: 18px;
        @include tablet-wide(){
            font-size: 20px;
        }
        br{
            display: none;
            @include tablet-wide(){
                display: initial;
            }
        }
    }
    &__row {
        margin-top: 30px;
        @include tablet(){
            display: flex;
            gap: 30px;
            margin-top: 60px;
        }
        &:nth-last-of-type(odd){
            flex-direction: row-reverse;
            .l-access{
                &__image {
                    @include tablet(){
                        border-radius: 0px 16px 16px 0px;
                    }
                }
            }
        }
    }
    &__content {
        flex: 1;
    }
    &__image {
        border-radius: 16px;
        overflow: hidden;
        // max-width: 690px;
        @include tablet(){
            width: 300px;
            border-radius: 16px 0px 0px 16px;
        }
        @include tablet-wide(){
            width: initial;
            flex: 1;
            // border-radius: 16px 0px 0px 16px;
        }
        picture,
        img{
            display: block;
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
    &__item {
        display: flex;
        gap: 30px;
        padding: 30px 0;
    }
    &__item-num {
        font-size: 28px;
        font-weight: bold;
        @include tablet-wide(){
            font-size: 40px;
        }
    }
    &__item-title {
        font-size: 28px;
        font-weight: bold;
        @include tablet-wide(){
            font-size: 32px;
        }
        @include laptop(){
            font-size: 40px;
        }
        br{
            display: none;
            @include tablet-wide(){
                display: initial;
            }
        }
    }
    &__item-text {
        line-height: 1.5;
        margin-top: 10px;
    }
    &__item-descr {
    }
}

.l-works {
    padding: 60px 0;
    @include tablet-wide(){
        padding: 100px 0;
    }
    &__title {
        text-align: center;
        font-size: 40px;
        font-weight: bold;
        text-transform: uppercase;
        @include tablet-wide(){
            font-size: 50px;
        }
        @include laptop(){
            font-size: 60px;
        }
        br{
            display: none;
            @include tablet-wide(){
                display: initial;
            }
        }
    }
    &__subtitle {
        text-align: center;
        margin-top: 15px;
        font-weight: 500;
        line-height: 1.5;
        font-size: 18px;
        @include tablet-wide(){
            font-size: 20px;
        }
        br{
            display: none;
            @include tablet-wide(){
                display: initial;
            }
        }
    }
    &__row {
        display: flex;
        gap: 30px;
        margin-top: 60px;
        flex-direction: column-reverse;
        @include tablet-wide(){
            flex-direction: row;
        }
    }
    &__steps {
        display: flex;
        flex-direction: column;
        gap: 24px;
        width: 100%;
        @include tablet(){
            flex-direction: row;
            flex-wrap: wrap;
        }
        @include tablet-wide(){
            max-width: 420px;
            gap: 24px;
        }
    }
    &__step {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        gap: 20px;
        border: 1px solid $color_black;
        padding: 20px;
        border-radius: 16px;
        @include tablet(){
            flex: 0 0 calc(50% - 12px);
            // margin-left: 24px;
        }
        @include tablet-wide(){
            padding: 10px 32px;
            min-height: 130px;
            flex: auto;
        }
        &.active{
            background-color: $color_yellow;
            border-color: $color_yellow;
            .l-works{
                &__step-num {
                    background-color: $color_black;
                    color: $color_white;
                }
            }
        }
    }
    &__step-num {
        flex: 0 0 48px;
        width: 48px;
        height: 48px;
        font-size: 24px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        font-weight: 600;
        background-color: $color_yellow;
        line-height: 1;
        @include tablet-wide(){
            flex: 0 0 64px;
            width: 64px;
            height: 64px;
            font-size: 32px;
        }
    }
    &__step-name {
        font-weight: 600;
        font-size: 20px;
        line-height: 1.2;
        .l-button{
            @include tablet(){
                font-size: 18px;
            }
            @include tablet-wide(){
                font-size: 20px;
            }
            &:hover{
                border-color: $color_black;
            }
        }
        @include tablet-wide(){
            font-size: 24px;
        }
    }
    &__image {
        border-radius: 16px;
        overflow: hidden;
        @include tablet-wide(){
            border-radius: 16px 0px 0px 16px;
        }
        picture,
        img{
            display: block;
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
}



.l-calc {
    background-color: $color_blue;
    padding: 60px 0;
    @include tablet-wide(){
        padding: 100px 0;
    }
    &__row {
        display: flex;
        gap: 30px;
    }
    &__image {
        display: none;
        @include tablet-wide(){
            display: block;
            flex: 0 0 410px;
            // position: relative;
        }
        picture,
        img{
            display: block;
            width: 100%;
            height: 100%;
            object-fit: cover;
            border-radius: 0px 24px 24px 0px;
            // position: absolute;
            // right: 0;
            // top: 0;
        }
        
    }
    &__content {
        flex: 1 1 100%;
    }
    
    &__count ,
    &__title {
        text-align: center;
        font-size: 40px;
        font-weight: bold;
        text-transform: uppercase;
        @include tablet-wide(){
            font-size: 50px;
        }
        @include laptop(){
            font-size: 60px;
        }
    }
    &__count {
        color: #fff;
        margin-top: 10px;
    }
    &__search {
    }
    &__search-row {
        display: flex;
        gap: 20px;
        margin-top: 20px;
        flex-direction: column;
        @include tablet(){
            flex-direction: row;
            gap: 30px;
            margin-top: 30px;
        }
    }
    &__search-col {
        flex: 1;
    }
    &__button{
        margin-top: 40px;
        display: flex;
        justify-content: center;
        .l-button{
            width: 100%;
            @include tablet(){
                max-width: 305px;
            }
        }
    }
}
.l-container {
}
.l-form {
    &__item {
    }
    &__label {
        margin-top: 10px;
    }
}
.l-select {
    display: block;
    width: 100%;
    background-color: transparent;
    border: none;
    border-bottom: 1px solid $color_white;
    height: 46px;
    font-size: 16px;
    font-weight: 600;
    color: $color_white;
    text-transform: uppercase;
    @include laptop(){
        font-size: 32px;
        height: 54px;
        text-align: center;
    }
    option{
        color: #000;
        font-size: 16px;
    }
}



.l-youget {
    background-color: $color_black;
    padding: 60px 0;
    color: $color_white;
    @include tablet-wide(){
        padding: 100px 0;
    }
    &__row {
        display: flex;
        flex-direction: column;
        @include tablet-wide(){
            flex-direction: row;
            gap: 30px;
        }
    }
    &__content {
        position: relative;
        z-index: 2;
    }
    &__title {
        font-size: 40px;
        font-weight: bold;
        text-transform: uppercase;
        @include tablet-wide(){
            font-size: 50px;
        }
        @include laptop(){
            font-size: 60px;
        }
        br{
            display: none;
            @include tablet-wide(){
                display: initial;
            }
        }
    }
    &__subtitle {
        margin-top: 15px;
        font-weight: 500;
        line-height: 1.5;
        font-size: 18px;
        @include tablet-wide(){
            font-size: 20px;
        }
        br{
            display: none;
            @include tablet-wide(){
                display: initial;
            }
        }
    }
    &__steps {
        display: flex;
        gap: 30px;
        margin-top: 30px;
        flex-direction: column;
        @include tablet(){
            flex-direction: row;
        }
        @include laptop(){
            margin-top: 60px;
        }
    }
    &__step {
        border: 1px solid #FFFFFF;
        border-radius: 16px;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 40px 20px;
        gap: 30px;
        @include tablet(){
            flex: 1;
        }
        @include laptop(){
            // flex: 0 0 293px;
            padding: 60px 20px;
        }
        &.active{
            background-color: $color_yellow;
            border-color: $color_yellow;
            color: $color_black;
            .l-youget{
                &__step-num {
                    background-color: $color_black;
                    color: $color_yellow;
                }

            }
        }
    }
    &__step-num {
        flex: 0 0 48px;
        width: 48px;
        height: 48px;
        font-size: 24px;
        display: flex;
        align-items: center;
        justify-content: center;
        line-height: 1;
        background-color: $color_yellow;
        border-radius: 50%;
        font-weight: 600;
        color: $color_black;
        @include laptop(){
            flex: 0 0 64px;
            width: 64px;
            height: 64px;
            font-size: 32px;
        }
    }
    &__step-title {
        text-align: center;
        font-size: 20px;
        font-weight: 600;
        line-height: 1.3;
        @include laptop(){
            font-size: 24px;
        }
    }
    &__image {
        border-radius: 16px;
        margin-top: 30px;
        display: none;
        position: relative;
        @include tablet-wide(){
            display: block;
        }
        @include laptop(){
            flex: 0 0 30%;
        }
        img{
            display: block;
            width: 100%;
            height: 100%;
            object-fit: cover;
            border-radius: 0px 24px 24px 0px;
            // position: absolute;
            // right: 0;
            // top: 0;
            @include tablet-wide(){
                width: calc(100% + 150px);
                max-width: calc(100% + 100px);
                height: calc(100% + 60px);
                margin-left: -150px;
                margin-bottom: -60px;
                z-index: -1;
            }
        }
    }
}

.l-partners {
    padding: 60px 0;
    @include tablet-wide(){
        padding: 100px 0;
    }
    &__title {
        font-size: 40px;
        font-weight: bold;
        text-transform: uppercase;
        @include tablet-wide(){
            font-size: 50px;
        }
        @include laptop(){
            font-size: 60px;
        }
    }
    &__list {
        display: flex;
        flex-wrap: wrap;
        margin-left: -30px;
        margin-top: 0;
        @include tablet-wide(){
            margin-top: 30px;
        }
    }
    &__item {
        flex: 0 0 calc(50% - 30px);
        margin-left: 30px;
        margin-top: 30px;
        border-radius: 16px;
        overflow: hidden;
        @include tablet(){
            flex: 0 0 calc(33.333% - 30px);
        }
        @include tablet-wide(){
            flex: 0 0 calc(25% - 30px);

        }
        picture,
        img{
            display: block;
        }
    }
}
.l-request {
    position: relative;
    &__bg {
        position: relative;
        &:before{
            content: '';
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
            background: linear-gradient(90deg, rgba(0, 0, 0, 0.4) 26.07%, rgba(0, 0, 0, 0) 73.06%);
            z-index: 1;
        }
        picture,
        img{
            min-height: 400px;
            max-height: 700px;
            display: block;
            width: 100%;
            height: 100%;
            object-fit: cover;
            max-width: initial;
        }
    }
    &__content {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: 0;
        width: 100%;
        z-index: 2;
    }
    &__block {
        width: 100%;
        max-width: 400px;
        @include tablet(){
            max-width: 440;
        }
        @include tablet-wide(){
            max-width: 530px;
        }
    }
    &__title {
        color: $color_white;
        font-size: 40px;
        font-weight: 700;
        line-height: 1.1;
    }
    &__form {
        margin-top: 15px;
    }
    &__form-row {
        margin-top: 35px;
        .l-button{
            width: 100%;
            max-width: 300px;
        }
    }
    &__input {
        width: 100%;
        display: block;
        padding: 0 15px;
        font-size: 16px;
        color: $color_white;
        border: none;
        border-bottom: 1px solid $color_white;
        height: 44px;
        background-color: transparent;
        outline: none;
        transition: .2s;
        &:focus{
            background-color: rgba($color: $color_white, $alpha: .1);
        }
        @include placeholder() {
            color: $color_white;
        }
    }
}
.l-footer {
    background-color: $color_black;
    color: $color_white;
    &__wrap {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 40px 0 30px;
        @include tablet(){
            padding-top: 60px;
        }
        @include tablet-wide(){
            padding-top: 90px;
        }
    }
    &__logo {
    }
    &__contacts {
        display: flex;
        margin-top: 25px;
        gap: 30px;
        flex-wrap: wrap;
    }
    &__contact {
        text-align: center;
    }
    &__contact-lbl {
        font-weight: 600;
        font-size: 20px;
        text-transform: uppercase;
        margin-bottom: 7px;
    }
    &__contact-value {
        a{
            color: $color_white;
            &:hover{
                color: $color_blue;
            }
        }
    }
    &__copyright {
        width: 100%;
        border-top: 1px solid  rgba(255, 255, 255, 0.5);
        padding: 24px 0 32px;
        text-align: center;
        color: #343434;
        line-height: 1.5;
        font-size: 14px;
    }
}
